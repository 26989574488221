<template>
  <div id="dashboard-analytics">
    <div>
      <h3 class="mb-5 mt-10">
        {{ $t("Dashboard") }}
      </h3>
    </div>
    <div class="flex flex-wrap items-center justify-between my-base">
      <div class="flex" id="select-dashboard" style="width: 42rem">
        <!-- <account-select class="mr-3"/> -->
        <v-select
          :options="chatOptions"
          v-if="activeChats === true"
          :clearable="false"
          v-model="chatFilter"
          class="w-full"
        />
      </div>
      <div class="flex items-center">
        <div class="btn-group mb-2" v-if="activeChats === true">
          <a
            href="#ultimos-7-dias"
            size="small"
            @click="rangeDays = 7"
            class="mb-base mr-3"
            type="flat"
            :class="{ isActive: rangeDays == 7 }"
            >Últimos 7 dias</a
          >
          <a
            href="#ultimos-30-dias"
            size="small"
            @click="rangeDays = 30"
            class="mb-base mr-3"
            type="flat"
            :class="{ isActive: rangeDays == 30 }"
            >Últimos 30 dias</a
          >
        </div>

        <date-range-picker
          v-if="activeChats === true"
          ref="picker"
          opens="left"
          :locale-data="localeDate"
          singleDatePicker="range"
          :showDropdowns="true"
          :ranges="false"
          v-model="dateRange"
        >
          <template v-slot:input="picker">
            <div class="flex mb-2 cursor-pointer">
              <a
                size="small"
                class="flex"
                type="flat"
                :class="{ isActive: rangeDays == 32 }"
              >
                Customizar
              </a>

              <p v-if="picker.length > 0">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </p>
            </div>
          </template>
        </date-range-picker>
      </div>
    </div>

    <div class="vx-row">
      <!-- CARD 1: TOTAL DE SESSÕES -->
      <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/5 xl:w-1/5 mb-base">
        <statistics-card-line
          icon="EyeIcon"
          icon-right
          :hideChart="false"
          :statistic="totalSessions"
          :statisticDiffOrientation="totalSessionsChangeOrientation"
          :statisticDiff="totalSessionsChange"
          statisticTitle="Sessões"
          helpCardText="Número total de sessões no intervalo de datas com o Chatbot instalado no seu Website. Uma sessão é o período de tempo em que um usuário interagiu ativamente com o seu Website."
          type="area"
          color="primary"
        ></statistics-card-line>
      </div>

      <!-- CARD 2: TOTAL DE ABERTURAS DO CHAT -->
      <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/5 xl:w-1/5 mb-base">
        <statistics-card-line
          icon="MousePointerIcon"
          icon-right
          :hideChart="false"
          :statistic="totalChatOpen"
          :statisticDiffOrientation="totalChatOpenChangeOrientation"
          :statisticDiff="totalChatOpenChange"
          statisticTitle="Aberturas do chat"
          helpCardText="Número total de vezes em que o Chatbot foi aberto."
          type="area"
          color="primary"
        ></statistics-card-line>
      </div>

      <!-- CARD 3: TOTAL DE CONVERSAS -->
      <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/5 xl:w-1/5 mb-base">
        <statistics-card-line
          icon="MessageCircleIcon"
          icon-right
          :hideChart="false"
          :statisticDiffOrientation="totalTalksChangeOrientation"
          :statisticDiff="totalTalksChange"
          :statistic="totalTalks"
          statisticTitle="Conversas"
          helpCardText="Número total de conversas completas e incompletas realizadas no Chatbot."
          color="primary"
          type="area"
        ></statistics-card-line>
      </div>

      <!-- CARD 4: TOTAL DE CONVERSAS -->
      <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/5 xl:w-1/5 mb-base">
        <statistics-card-line
          icon="UserPlusIcon"
          icon-right
          :hideChart="false"
          :statisticDiffOrientation="totalLeadsChangeOrientation"
          :statisticDiff="totalLeadsChange"
          :statistic="totalLeads"
          statisticTitle="Leads"
          helpCardText="Número total de conversas completas realizadas no Chatbot."
          color="primary"
          type="area"
        ></statistics-card-line>
      </div>

      <!-- CARD 5: CONVERSÃO DO CHATBOT -->
      <div class="vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/5 xl:w-1/5 mb-base">
        <statistics-card-line
          icon="ShoppingBagIcon"
          icon-right
          :hideChart="false"
          :statisticDiffOrientation="conversionChangeOrientation"
          :statisticDiff="conversionChange"
          :statistic="conversion + '%'"
          statisticTitle="Conversão"
          helpCardText="O número que é a divisão entre Leads e Sessões do Chatbot."
          color="primary"
          type="area"
        ></statistics-card-line>
      </div>
    </div>

    <div class="vx-row">
      <!-- CARD 5: DISPATCHED ORDERS -->
      <div class="vx-col w-full">
        <vx-card title="Últimas Conversas">
          <div slot="no-body" class="mt-4">
            <vs-table
              :data="leadsData"
              v-if="leadsData.length"
              class="table-dark-inverted"
            >
              <template slot="thead">
                <vs-th>NOME</vs-th>
                <vs-th>EMAIL</vs-th>
                <vs-th>STATUS</vs-th>
                <vs-th>TAGS</vs-th>
                <vs-th>TIPO</vs-th>
                <vs-th>CHAT</vs-th>
                <vs-th>DATA</vs-th>
                <vs-th>AÇÃO</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  @click="goToLeadChat(chatNo.id)"
                >
                  <vs-td :data="data[indextr].chatNo">
                    <span>{{ data[indextr].name }}</span>
                  </vs-td>

                  <vs-td :data="data[indextr].chatNo">
                    <span>{{ data[indextr].email }}</span>
                  </vs-td>

                  <vs-td :data="data[indextr].chatNo">
                    <vs-chip :color="chipColor(data[indextr].isLead)">
                      <span>{{ data[indextr].isLead }}</span>
                    </vs-chip>
                  </vs-td>

                  <vs-td :data="data[indextr].intentions">
                    <div class="flex mt-2">
                      <vs-chip
                        v-for="(intention, index) in data[indextr].intentions"
                        :key="index"
                        style="height: 30px"
                      >
                        <span>{{ intention }}</span>
                      </vs-chip>
                    </div>
                  </vs-td>

                  <vs-td :data="data[indextr].chatNo">
                    <span>{{ data[indextr].channel }}</span>
                  </vs-td>

                  <vs-td :data="data[indextr].chatNo">
                    <span>{{ data[indextr].chat }}</span>
                  </vs-td>

                  <vs-td :data="data[indextr].chatNo">
                    <span
                      >{{ data[indextr].date }} {{ data[indextr].hour }}</span
                    >
                  </vs-td>

                  <vs-td :data="data[indextr].chatNo">
                    <cell-renderer-actions
                      :leadID="data[indextr].id"
                    ></cell-renderer-actions>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <div v-else class="vx-col w-full text-center mb-4">
              Nenhuma conversa a mostrar
            </div>
          </div>

          <vs-button
            color="success"
            type="border"
            vs-justify="center"
            v-if="leadsData.length > 0"
            vs-w="12"
            @click="$router.push('/apps/leads').catch(() => {})"
            >Ver mais conversas</vs-button
          >
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";

import moduleLeadsManagement from "@/store/leads-management/moduleLeadsManagement.js";

import CellRendererActions from "@/views/apps/leads/cell-renderer/CellRendererActions.vue";

import vSelect from "vue-select";

import DateRangePicker from "vue2-daterange-picker";
// import AccountSelect from "./apps/acc/components/AccountSelect.vue";

export default {
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      localeDate: {
        direction: "ltr",
        format: "mm/dd/yyyy",
        separator: " - ",
        applyLabel: "Escolher",
        cancelLabel: "Cancelar",
        weekLabel: "s",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 1,
      },
      activeChats: true,
      pendingChats: "",

      chatOptions: [{ label: this.$t("AllChatbots"), value: "all" }],
      chatFilter: { label: this.$t("AllChatbots"), value: "all" },
      chatID: null,

      rangeDays: 7,

      totalSessions: 0,
      totalSessionsChange: 0,
      totalSessionsChangeOrientation: "",

      totalChatOpen: 0,
      totalChatOpenChange: 0,
      totalChatOpenChangeOrientation: "",

      totalTalks: 0,
      totalTalksChange: 0,
      totalTalksChangeOrientation: "",

      totalLeads: 0,
      totalLeadsChange: 0,
      totalLeadsChangeOrientation: "",

      conversion: 0,
      conversionChange: 0,
      conversionChangeOrientation: "",

      isActive: false,

      chats: [],
      kpiChart: {
        sessions: [
          {
            name: "Traffic Rate",
            data: [150, 200, 125, 225, 200, 250],
          },
        ],
      },
    };
  },
  components: {
    StatisticsCardLine,
    vSelect,
    CellRendererActions,
    DateRangePicker,
    // AccountSelect,
  },
  methods: {
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          if (this.chatOptions.length == 1) {
            this.activeChats = false;
            for (var i = 0; i < response.data.data.length; i++) {
              this.chatOptions.push({
                label: response.data.data[i].name,
                value: response.data.data[i]._id,
              });

              if (response.data.data[i].status == 1) this.activeChats = true;
              if (response.data.data[i].status == 3) this.pendingChats = true;
            }
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getLeads() {
      if (!moduleLeadsManagement.isRegistered) {
        this.$store.registerModule("leadsManagement", moduleLeadsManagement);
        moduleLeadsManagement.isRegistered = true;
      }
      this.$store
        .dispatch("leadsManagement/fetchLeads", {
          acc: this.$store.state.acc.current_acc.id,
          chat: this.chatID,
          Lead: this.$t("Lead"),
          Talk: this.$t("Talk"),
          Complete: this.$t("Complete"),
          Incomplete: this.$t("Incomplete"),
          NotInformed: this.$t("NotInformed"),
          limit: 10,
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["Leads"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getData() {
      this.totalSessions = 0;
      this.totalSessionsChange = 0;
      this.totalSessionsChangeOrientation = "";

      this.totalChatOpen = 0;
      this.totalChatOpenChange = 0;
      this.totalChatOpenChangeOrientation = "";

      this.totalTalks = 0;
      this.totalTalksChange = 0;
      this.totalTalksChangeOrientation = "";

      this.totalLeads = 0;
      this.totalLeadsChange = 0;
      this.totalLeadsChangeOrientation = "";

      this.conversion = 0;
      this.conversionChange = 0;
      this.conversionChangeOrientation = "";
      let rangeStart = null,
        rangeEnd = null;
      if (this.rangeDays > 31) {
        rangeStart = this.dateRange.startDate;
        rangeEnd = this.dateRange.endDate;
      }
      var params = {
        acc: this.$store.state.acc.current_acc.id,
        rangeDays: this.rangeDays,
        rangeStart: rangeStart,
        rangeEnd: rangeEnd,
      };
      if (this.chatID != null) params.chat = this.chatID;
      this.$http
        .get("p/chat/kpis", {
          params: params,
        })
        .then((response) => {
          this.$vs.loading.close();
          var KpisA = response.data.data[0];
          var KpisB = response.data.data[1];
          var sessionsA = 0,
            chatOpenA = 0,
            totalTalksA = 0,
            totalLeadsA = 0;

          for (var i = 0; i < KpisA.length; i++) {
            switch (KpisA[i]._id.kpiKey) {
              case "numChatSessions":
                sessionsA = parseInt(KpisA[i].count);
                break;
              case "numChatOpen":
                chatOpenA = parseInt(KpisA[i].count);
                break;
              case "numTalks":
                totalTalksA = parseInt(KpisA[i].count);
                break;
              case "numLeads":
                totalLeadsA = parseInt(KpisA[i].count);
                break;
            }
          }
          var conversionA = Math.round(
            sessionsA > 0 ? (totalLeadsA / sessionsA) * 100 : 0
          );

          for (i = 0; i < KpisB.length; i++) {
            switch (KpisB[i]._id.kpiKey) {
              case "numChatSessions":
                this.totalSessions = KpisB[i].count;
                this.totalSessionsChange = Math.round(
                  sessionsA > 0
                    ? ((parseInt(KpisB[i].count) - sessionsA) * 100) / sessionsA
                    : 0
                );
                this.totalSessionsChangeOrientation =
                  this.totalSessionsChange == 0
                    ? ""
                    : this.totalSessionsChange > 0
                    ? "up"
                    : "down";
                break;
              case "numChatOpen":
                this.totalChatOpen = KpisB[i].count;
                this.totalChatOpenChange = Math.round(
                  chatOpenA > 0
                    ? ((parseInt(KpisB[i].count) - chatOpenA) * 100) / chatOpenA
                    : 0
                );
                this.totalChatOpenChangeOrientation =
                  this.totalChatOpenChange == 0
                    ? ""
                    : this.totalChatOpenChange > 0
                    ? "up"
                    : "down";
                break;
              case "numTalks":
                this.totalTalks = KpisB[i].count;
                this.totalTalksChange = Math.round(
                  totalTalksA > 0
                    ? ((parseInt(KpisB[i].count) - totalTalksA) * 100) /
                        totalTalksA
                    : 0
                );
                this.totalTalksChangeOrientation =
                  this.totalTalksChange == 0
                    ? ""
                    : this.totalTalksChange > 0
                    ? "up"
                    : "down";
                break;
              case "numLeads":
                this.totalLeads = KpisB[i].count;
                this.totalLeadsChange = Math.round(
                  totalLeadsA > 0
                    ? ((parseInt(KpisB[i].count) - totalLeadsA) * 100) /
                        totalLeadsA
                    : 0
                );
                this.totalLeadsChangeOrientation =
                  this.totalLeadsChange == 0
                    ? ""
                    : this.totalLeadsChange > 0
                    ? "up"
                    : "down";
                break;
            }

            this.conversion = (
              this.totalSessions > 0
                ? (this.totalLeads / this.totalSessions) * 100
                : 0
            ).toFixed(2);
            this.conversionChange = (
              conversionA > 0
                ? (this.conversion - conversionA) / conversionA
                : 0
            ).toFixed(2);
            this.conversionChangeOrientation =
              this.conversionChange == 0
                ? ""
                : this.conversionChange > 0
                ? "up"
                : "down";
          }
        })
        .catch(() => {});
    },
    mountedFunc() {
      this.getChats();
      this.getData();
      this.getLeads();
      this.$vs.loading.close();
    },
  },
  computed: {
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    hasInbox: function () {
      return this.planData["inboxAvailable"];
    },
    leadsData() {
      return this.$store.state.leadsManagement &&
        this.$store.state.leadsManagement.leads
        ? this.$store.state.leadsManagement.leads
        : [];
    },
    chipColor() {
      return (value) => {
        if (value === "Lead") return "success";
        else "primary";
      };
    },
  },
  watch: {
    "$store.state.acc.current_acc.id"() {
      this.mountedFunc();
    },
    hasInbox() {
      if (this.hasInbox) {
        this.$router.push(
          `new-dashboard${
            this.$router.query ? "acc=" + this.$router.query.acc : ""
          }`
        );
      }
    },
    dateRange() {
      this.rangeDays = 32;
      this.getData();
    },
    chatFilter(val) {
      this.chatID = val.value == "all" ? null : val.value;
      this.getData();
      this.getLeads();
    },
    rangeDays() {
      this.getData();
    },
  },
  mounted() {
    this.$vs.loading.close();
    this.mountedFunc();
    if (this.hasInbox) {
      this.$router.push(
        `new-dashboard${
          this.$router.query ? "acc=" + this.$router.query.acc : ""
        }`
      );
    }
  },
  updated() {
    if (this.hasInbox) {
      this.$router.push(
        `new-dashboard${
          this.$router.query ? "acc=" + this.$router.query.acc : ""
        }`
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.drp-selected {
  display: none !important;
}
.off {
  color: #62626273 !important;
}
.table-condensed th,
.table-condensed td {
  color: #626262;
  text-align: center;
}
.table-condensed th {
  padding: 10px;
}
.daterangepicker {
  position: absolute;
  z-index: 800;
  border: 1px solid #ccc;
  right: 0;
  width: min-content;
  background-color: white;
}
.drp-buttons {
  display: flex;
  justify-content: flex-end;
}
.month {
  padding: 18px 0 10px 30px !important;
}
.monthselect {
  padding: 0 0.75rem 0 0;
  color: #626262;
  margin-right: 14px;
  border: none;
  font-size: 16px;
}
.yearselect {
  padding: 0 0.75rem 0 0;
  color: #626262;
  border: none;
  width: 40%;
  font-size: 16px;
}
.table-condensed th > .row {
  display: flex;
  justify-content: center;
}
.end-date,
.start-date {
  background-color: rgba(var(--vs-primary), 1) !important;
  color: white;
}
.in-range {
  background-color: rgba(var(--vs-primary), 0.2);
}
.cancelBtn {
  border: none;
  outline: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.61);
  border-radius: 5px;
  margin: 1rem;
}
.applyBtn {
  background-color: rgba(var(--vs-primary), 1);
  border: none;
  outline: none;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  margin: 1rem;
  margin: 1rem 1rem 1rem 0;
}
.vs-chip-success {
  background: rgba(var(--vs-success), 0.15);
  font-weight: 500;
}
.vs-chip-warning {
  background: rgba(var(--vs-warning), 0.15);
  font-weight: 500;
}
.vs-chip-danger {
  background: rgba(var(--vs-danger), 0.15);
  font-weight: 500;
}
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */

.vs-button-border.isActive span {
  color: #7367f0 !important;
}

a.isActive {
  border-bottom: 2px solid #7367f0;
}
</style>
